import api from './index';

export type WalletModel = {
  blockchain_id: string;
  global_id: number;
  wallet_id: string;
  blockchain_name: string;
  signature?: string;
  wallet_address: string;
};

export type LootboxWalletsModel = {
  data: { blockchain_wallets: WalletModel[] };
  meta: any;
};

export enum TransactionStatus {
  New = 'NEW',
  Approved = 'APPROVED',
  Confirmed = 'CONFIRMED',
  Cancelled = 'CANCELLED',
  Fulfilled = 'FULFILLED',
  Unknown = 'UNKNOWN',
}

export const getWalletsApi = () =>
  api.get<LootboxWalletsModel>('partner/blockchain/wallet-blockchain')

export const mockWallets: LootboxWalletsModel = {
  data: {
    blockchain_wallets: [
      {
        wallet_id: '6dd62879-e1b9-46c6-962f-ef008150fb07',
        wallet_address: '0x7822a368Bd82Ee3502ddAa183c29a5dcFF61CCE9',
        blockchain_id: 'f045eb6e-c800-4907-9658-6d1987647abf',
        blockchain_name: 'Arbitrum',
        global_id: 42161,
      },
      {
        wallet_id: '6dd62879-e1b9-46c6-962f-ef008150fb07',
        wallet_address: '0x7822a368Bd82Ee3502ddAa183c29a5dcFF61CCE9',
        blockchain_id: 'f045eb6e-c800-4907-9658-6d1987647abf',
        blockchain_name: 'Arbitrum',
        global_id: 42161,
      },
      {
        wallet_id: '61687ac0-35ee-4d05-9a32-5ce04224b5a5',
        wallet_address: '0x7822a368Bd82Ee3502ddAa183c29a5dcFF61CCE9',
        blockchain_id: 'c57e69f3-af2b-47af-a68e-2978f9b88b3a',
        blockchain_name: 'Polygon',
        global_id: 137,
      },
      {
        wallet_id: '61687ac0-35ee-4d05-9a32-5ce04224b5a5',
        wallet_address: '0x7822a368Bd82Ee3502ddAa183c29a5dcFF61CCE9',
        blockchain_id: 'c57e69f3-af2b-47af-a68e-2978f9b88b3a',
        blockchain_name: 'Polygon',
        global_id: 137,
      },
      {
        wallet_id: '21e026c9-6aad-45b4-82b7-2fc2e8cf97d2',
        wallet_address: '0x7822a368Bd82Ee3502ddAa183c29a5dcFF61CCE9',
        blockchain_id: 'f8086839-6401-42ed-aeea-d4dcf67255d6',
        blockchain_name: 'BNB Smart Network',
        global_id: 56,
      },
      {
        wallet_id: '21e026c9-6aad-45b4-82b7-2fc2e8cf97d2',
        wallet_address: '0x7822a368Bd82Ee3502ddAa183c29a5dcFF61CCE9',
        blockchain_id: 'f8086839-6401-42ed-aeea-d4dcf67255d6',
        blockchain_name: 'BNB Smart Network',
        global_id: 56,
      },
    ],
  },
  meta: {
    has_more: false,
  },
};
