import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { Slider } from '../components/slider/Slider';
import { CaseItems } from '../components/cases/case-items/CaseItems';
import { RollerContainer } from '../components/roller-container/RollerContainer';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { mockCases } from '../api/cases';
import { useLootboxStore } from '../store';
import { Spinner } from '../components/spinner';
import { ModalWin } from '../components/cases/modal-win';

type Props = {
  handleOpenLoginModal: () => void;
  handleOpenWarningDepositModal: () => void;
}
export const CasesContainer = ({ handleOpenLoginModal, handleOpenWarningDepositModal }: Props) => {
  const { getLootboxes, lootboxes, loading, openLootbox, opening, user } = useLootboxStore();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [start, setStart] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const onOpenLoot = () => {
    if (user?.id) {
      if (user.attemptBalance) {
        if (isMobile) {
          setStart(false);
          setIsOpenModal(true);
        } else {
          setStart((prevState) => !prevState);
        }
        // todo change value
        openLootbox('id');
      } else {
        handleOpenWarningDepositModal();
      }
    } else {
      handleOpenLoginModal();
    }
  };

  useEffect(() => {
    getLootboxes();
  }, []);

  return (
    <div style={{ marginBottom: '78px' }}>
      {loading ? (
        <Spinner full />
      ) : (
        <>
          <Slider onOpen={onOpenLoot} />
          {!isMobile && (
            <RollerContainer
              isRunning={start}
              gifts={mockCases[0].gifts}
              giftIndex={30}
              onGiftDefined={() => setIsOpenModal(true)}
            />
          )}
          <CaseItems gifts={mockCases[0].gifts} />
          {isOpenModal ? (
            <ModalWin
              gift={mockCases[0].gifts[1]}
              onClose={() => setIsOpenModal(false)}
              isDesktop={!isMobile}
              loading={opening}
            />
          ) : null}
        </>
      )}
    </div>
  );
};
