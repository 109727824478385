import {
  arbitrumUsdt, arbitrumUsdtUnit,
  bnbSmartChainBep20Usdt, bnbSmartChainBep20UsdtUnit,
  mainnetErc20Usdt,
  mainnetErc20UsdtUnit, polygonUsdt, polygonUsdtUnit
} from './tokens';
import { erc20ABI } from 'wagmi';
import { abiArbitrumUsdt, abiBep20Usdt, abiPolygonUsdt } from './abi';
import type { Abi, Address } from 'viem';

export interface ContractArgs {
  address: Address;
  abi: Abi;
  units: number;
  recipient: Address;
}

export const contractsConfig: Record<number, Omit<ContractArgs, 'recipient'>> = {
  1: {
    address: mainnetErc20Usdt,
    abi: erc20ABI,
    units: mainnetErc20UsdtUnit,
  },
  56: {
    address: bnbSmartChainBep20Usdt,
    abi: abiBep20Usdt,
    units: bnbSmartChainBep20UsdtUnit,
  },
  42161: {
    address: arbitrumUsdt,
    abi: abiArbitrumUsdt,
    units: arbitrumUsdtUnit,
  },
  137: {
    address: polygonUsdt,
    abi: abiPolygonUsdt,
    units: polygonUsdtUnit,
  },
} as const;
