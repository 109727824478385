import React from 'react';
import { Button, Modal } from 'antd';
import styles from './index.module.css';
import TgLogo from "src/assets/images/svg/telegram-black-logo.svg";
import WarningImg from "src/assets/images/png/warning.png";

type Props = {
  onClose: () => void;
  handleOpenDepositFlowModal: () => void;
};
export const ModalNoMoney= (props: Props) => {
  const { onClose, handleOpenDepositFlowModal } = props;
  return (
    <Modal open footer={null} onCancel={onClose} className={styles.modal}>
      <div className={styles.gradient}></div>
      <img className={styles.logo} src={WarningImg} alt=""/>
      <div className={styles.title}>Недостаточно средств</div>
      <div className={styles.subtitle}>
        У вас недостаточно средств для покупки выбранного лутбокса.
      </div>
      <Button className={styles.button} type={"primary"} onClick={handleOpenDepositFlowModal}>
        Пополнить баланс
      </Button>
    </Modal>
  );
};
