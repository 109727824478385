import React from 'react';
import { Button, Modal } from 'antd';
import { GiftItem } from '../../../api/cases';
import { GiftItemCard } from '../gift-item';
import styles from './index.module.css';
import classNames from 'classnames';
import { getGiftColor } from '../gift-item/getData';
import { Spinner } from '../../spinner';
import { LootboxOpen } from '../../lootbox-open';

type Props = {
  onClose: () => void;
  gift?: GiftItem;
  isDesktop: boolean;
  loading?: boolean;
};
export const ModalWin = (props: Props) => {
  const { onClose, gift, isDesktop } = props;
  if (isDesktop) {
    return (
      <Modal open footer={null} onCancel={onClose} className={styles.modal}>
        <div className={classNames(styles.gradient, styles[getGiftColor(gift?.type)])}></div>
        <GiftItemCard data={gift!} size={'giant'} isRollerView />
        <div className={styles.title}>{gift?.name}</div>
        <div className={styles.subtitle}>
          Поздравляем! Вы выиграли <b>{gift?.name}</b>. Вы можете забрать свой приз во вкладке "Выигрыш" -{'>'}{' '}
          "Вывести"
        </div>
        <Button type={'primary'} onClick={onClose} style={{ width: '100%' }}>
          Отлично!
        </Button>
      </Modal>
    );
  }
  return <MobileModalWin {...props} />;
};

const MobileModalWin = ({ gift, onClose, loading }: Props) => {
  return (
    <Modal
      open
      footer={null}
      onCancel={onClose}
      className={styles.modal}
      classNames={{ content: classNames(styles.modalContent, loading && styles.loading) }}
    >
      {loading ? (
        <Spinner full />
      ) : (
        <>
          <LootboxOpen onOpenLoot={() => {}} />
          <div className={styles.content}>
            <div className={classNames(styles.gradient, styles[getGiftColor(gift?.type)])}></div>
            <GiftItemCard data={gift!} size={'giant'} isRollerView/>
            <div className={styles.title}>{gift?.name}</div>
            <div className={styles.subtitle}>
              Поздравляем! Вы выиграли <b>{gift?.name}</b>. Вы можете забрать свой приз во вкладке "Выигрыш" -{'>'}{' '}
              "Вывести"
            </div>
            <Button type={'primary'} onClick={onClose} style={{width: '100%'}}>
              Отлично!
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
