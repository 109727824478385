import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import CopyImg from 'src/assets/images/svg/copy.svg';
import { Space } from '../../ant/space';
import { chainIcons } from '../../../utils/wagmi/icons';
import { WalletModel } from '../../../api/wallets';
import { uniqBy } from '../../../utils/uniqBy';
import styles from './index.module.css';
import { TotalContent } from './total-content';

type Props = {
  walletsData: WalletModel[];
  prev: () => void;
  volume: number;
  onSubmit: (hash: string) => void;
};

export const QRPaymentContent = ({ walletsData, prev, volume, onSubmit }: Props) => {
  const [activeNetwork, setActiveNetwork] = useState('');
  const [hash, setHash] = useState('');
  const [isShowHashForm, setIsShowHashForm] = useState(false);
  const [refreshQR, setRefreshQR] = useState(false);
  const addressOptions = useMemo(() => {
    return uniqBy<WalletModel>(walletsData, (v) => v.global_id).map((blockchain, index: number) => ({
      key: index,
      value: blockchain.global_id,
      label: (
        <Space size={8} full>
          {chainIcons[blockchain.global_id] && (
            <img src={chainIcons[blockchain.global_id]} alt={blockchain.blockchain_name} />
          )}
          {blockchain.blockchain_name}
        </Space>
      ),
      data: { address: blockchain.wallet_address },
    }));
  }, [walletsData]);
  const handleBack = () => {
    if (isShowHashForm) {
      setIsShowHashForm(false);
    } else {
      prev();
    }
  };

  useEffect(() => {
    if (addressOptions.length) {
      setActiveNetwork(addressOptions[0].data.address);
    }
  }, []);

  return (
    <div>
      {isShowHashForm ? null : (
        <>
          <div className={styles.label}>Сеть</div>
          <Select
            size="large"
            defaultValue={addressOptions[0].value}
            onSelect={(_, option) => {
              setActiveNetwork(option.data.address);
              setRefreshQR(!refreshQR);
              setTimeout(() => setRefreshQR(!refreshQR));
            }}
            className={styles.select}
            options={addressOptions}
          />
        </>
      )}
      {activeNetwork && !isShowHashForm ? (
        <QRCodeSVG
          value={activeNetwork}
          size={235}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'L'}
          marginSize={1}
          style={{ display: 'block', margin: '24px auto' }}
        />
      ) : null}
      {activeNetwork && !isShowHashForm ? (
        <>
          <div className={styles.label}>Адрес депозита</div>
          <Input
            value={activeNetwork}
            onChange={() => {}}
            disabled
            className={styles.inputAddress}
            addonAfter={
              <img
                src={CopyImg}
                width={24}
                height={24}
                onClick={() => navigator.clipboard.writeText(activeNetwork)}
                alt=""
              />
            }
          />
        </>
      ) : null}
      {isShowHashForm ? (
        <div className={styles.inputHash}>
          <div className={styles.label}>Хеш транзакции</div>
          <Input value={hash} onChange={(e) => setHash(e.target.value)} />
        </div>
      ) : null}
      <TotalContent volume={volume} />
      <div className={styles.buttonsWrapper}>
        <Button type={'default'} size={'large'} className={styles.backButton} onClick={handleBack}>
          Назад
        </Button>
        {isShowHashForm ? (
          <Button type={'default'} size={'large'} disabled={!hash} onClick={() => onSubmit(hash)}>
            Завершить
          </Button>
        ) : (
          <Button type={'default'} size={'large'} disabled={!activeNetwork} onClick={() => setIsShowHashForm(true)}>
            Ввести хеш
          </Button>
        )}
      </div>
    </div>
  );
};
