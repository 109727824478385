import React, { FC } from 'react';
import { Spin, SpinProps } from 'antd';
import classNames from 'classnames';
import styles from './Spinner.module.scss';

export const Spinner: FC<SpinProps & { full?: boolean }> = (props) => {
  const { className, full, ...spinProps } = props;

  return (
    <Spin {...spinProps} className={classNames(styles.spinner, { [styles.spinner_full]: full, className })} />
  );
};
