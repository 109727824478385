type Predicate<T> = (item: T) => string | number;
export const uniqBy = <T>(array: T[], predicate: Predicate<T>): T[] => {
  const seen = new Set<string | number>();
  const result: T[] = [];

  for (const item of array) {
    const key = predicate(item);
    if (!seen.has(key)) {
      seen.add(key);
      result.push(item);
    }
  }

  return result;
};
