import React from 'react';
import { Button, Modal } from 'antd';
import styles from './index.module.css';
import SuccessImg from "src/assets/images/png/success.png";

type Props = {
  onClose: () => void;
};
export const ModalDepositSuccess = (props: Props) => {
  const { onClose } = props;
  return (
    <Modal open footer={null} onCancel={onClose} className={styles.modal}>
      <img className={styles.logo} src={SuccessImg} alt=""/>
      <div className={styles.title}></div>
      <div className={styles.subtitle}>
        В ближайшее время средства будут зачислены на Ваш баланс
      </div>
      <Button className={styles.button} type={"primary"} onClick={onClose}>
        Отлично
      </Button>
    </Modal>
  );
};
