import React from 'react';
import { AltTag } from 'src/components/tags/tag/Tag';
import { Space } from 'src/components/ant/space';
import { Typography } from 'src/components/ant/typography';

import styles from './TagsFilter.module.scss';

export interface TagsFilterProps<T> {
  title: string;
  active: T | undefined;
  list: Array<{
    title: string;
    value: T | undefined;
  }>;
  onClick: (value: T | undefined) => void;
  isMobile?: boolean;
  direction?: 'horizontal' | 'vertical';
  className?: string;
}

export const TagsFilter = <T,>({ list, active, title, onClick, isMobile, direction, className }: TagsFilterProps<T>) => {
  return (
    <Space size={16} direction={direction} align='center' className={className}>
      <Typography.Text strong className={styles.title}>{title}</Typography.Text>
      <div className={styles.tags}>
        {list.map((tag, i) => {
          const activeTag = tag.value === active;
          return (
            <div className={styles.tag} key={tag.title + String(i)}>
              <AltTag
                onClick={() => onClick(tag.value)}
                size={isMobile ? 'middle' : 'large'}
                type={activeTag ? 'success' : 'default'}
                noborder={!activeTag}
                clickable={!activeTag}
              >
                {tag.title}
              </AltTag>
            </div>
          );
        })}
      </div>
    </Space>
  );
};
