import api from './index';

export type HistoryTransactionsItem = {
  blockchain_id: string;
  created_at: string;
  hash: string;
  id: string;
  status: HistoryStatus;
  updated_at: string;
  user_address: string;
  user_id: string;
  volume: number;
};
export enum HistoryStatus {
  All = "ALL",
  Done = "DONE",
  Error = 'ERROR',
}

export type HistoryFilters = {
  status?: HistoryStatus;
  from?: string;
  to?: string;
  userId?: string;
}
export const getHistoryTransactionsApi = (params: HistoryFilters) =>
  api.get<HistoryTransactionsItem[]>('partner/deposit_transactions', { params });
