// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
import { configureChains, createConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { arbitrum, mainnet, polygon, bsc } from 'viem/chains';
import {REACT_APP_ALCHEMY_PUBLIC_KEY, REACT_APP_WALLET_CONNECT_PROJECT_ID} from "../../constants";

export enum WagmiConnectorsIds {
  MetaMask = 'metaMask',
  WalletConnect = 'walletConnect',
  CoinbaseWallet = 'coinbaseWallet'
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, arbitrum, polygon, bsc],
  [alchemyProvider({ apiKey: REACT_APP_ALCHEMY_PUBLIC_KEY ?? '' }), publicProvider()],
);

export const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '',
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Alloca',
        appLogoUrl: 'https://app.alloca.io/favicon-32x32.png'
      }
    })
  ],
  publicClient,
  webSocketPublicClient,
});
