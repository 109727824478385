import {GiftListItemModel, GiftTypeEnum, StatusWin} from "../../api/gift";

export const photo1 = '';

export const mockTable: GiftListItemModel[] = [
  {
    id: '1',
    name: 'Bitcoin',
    photo_id: photo1,
    received_at: '10.02.2023',
    status: StatusWin.New,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '2',
    name: 'Merch Alloca',
    photo_id: photo1,
    received_at: '20.02.2023',
    status: StatusWin.Completed,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '3',
    name: 'Ethereum',
    photo_id: photo1,
    received_at: '15.03.2023',
    status: StatusWin.New,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '4',
    name: 'Ripple',
    photo_id: photo1,
    received_at: '05.04.2023',
    status: StatusWin.New,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '5',
    name: 'Litecoin',
    photo_id: photo1,
    received_at: '18.05.2023',
    status: StatusWin.New,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '6',
    name: 'Cardano',
    photo_id: photo1,
    received_at: '22.06.2023',
    status: StatusWin.Completed,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '7',
    name: 'Polkadot',
    photo_id: photo1,
    received_at: '30.07.2023',
    status: StatusWin.Completed,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '8',
    name: 'Stellar',
    photo_id: photo1,
    received_at: '12.08.2023',
    status: StatusWin.New,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '9',
    name: 'Chainlink',
    photo_id: photo1,
    received_at: '25.09.2023',
    status: StatusWin.Completed,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
  {
    id: '10',
    name: 'Dogecoin',
    photo_id: photo1,
    received_at: '07.10.2023',
    status: StatusWin.New,
    type: GiftTypeEnum.Merch,
    price: 15,
    currency: 'USDT'
  },
]
