import type { Chain } from 'viem';
import EthereumSvg from 'src/assets/images/svg/networks/ethereum.svg';
import BepSvg from 'src/assets/images/svg/networks/bep.svg';
import ArbitrumSvg from 'src/assets/images/svg/networks/arbitrum.svg';
import PolygonSvg from 'src/assets/images/svg/networks/polygon.svg';

export const chainIcons: Record<Chain['id'], string> = {
  1: EthereumSvg,
  56: BepSvg,
  42161: ArbitrumSvg,
  137: PolygonSvg
}
