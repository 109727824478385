import { Typography } from '../ant/typography';
import React, { useEffect } from 'react';
import MainLogo from '../../assets/images/png/mainLogo.png';

import styles from './header.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { PagesEnum } from '../../constants';
import { BalanceBlock } from 'src/components/header/balanceBlock/BalanceBlock';
import { useLootboxStore } from '../../store';

type Props = {
  handleLogin: () => void;
  setIsOpenDepositFlowModal: (is: boolean) => void;
};
export const Header = ({ handleLogin, setIsOpenDepositFlowModal }: Props) => {
  const user = useLootboxStore((state) => state.user);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    if (user?.id) {
      switch (e.target.id) {
        case `main_tab_${PagesEnum.Cases}`:
          navigate(PagesEnum.Cases);
          return;

        case `main_tab_${PagesEnum.Win}`:
          navigate(`/${PagesEnum.Win}`);
          return;

        case `main_tab_${PagesEnum.History}`:
          navigate(`/${PagesEnum.History}`);
          return;

        default:
          return;
      }
    } else {
      navigate(PagesEnum.Cases);
    }
  };

  const casesTabType = pathname === PagesEnum.Cases ? 'success' : undefined;
  const winTabType = pathname === `/${PagesEnum.Win}` ? 'success' : undefined;
  const historyTabType = pathname === `/${PagesEnum.History}` ? 'success' : undefined;

  useEffect(() => {
    // todo return this
    // if (!user?.id) {
    //   navigate(PagesEnum.Cases);
    // }
  }, [pathname, user?.id]);

  return (
    <div className={styles.container}>
      <img src={MainLogo} className={styles.logo} alt="main-logo" />

      <div className={styles.tabs}>
        <Typography.Title
          id={`main_tab_${PagesEnum.Cases}`}
          level={2}
          type={casesTabType}
          onClick={handleClick}
          className={styles.tab}
        >
          Крипто-кейсы
        </Typography.Title>

        <Typography.Title
          id={`main_tab_${PagesEnum.Win}`}
          level={2}
          type={user?.id ? winTabType : 'secondary'}
          onClick={handleClick}
          className={styles.tab}
        >
          Выигрыш
        </Typography.Title>

        <Typography.Title
          id={`main_tab_${PagesEnum.History}`}
          level={2}
          type={user?.id ? historyTabType : 'secondary'}
          onClick={handleClick}
          className={styles.tab}
        >
          Транзакции
        </Typography.Title>
      </div>

      <BalanceBlock
        user={user}
        handleLogin={handleLogin}
        setIsOpenDepositFlowModal={setIsOpenDepositFlowModal}
      />
    </div>
  );
};
