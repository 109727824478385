import {GetLootBoxListItem, GiftItem} from '../../../api/cases';
import { GiftTypeEnum } from '../../../api/gift';
import ImgBaseCoins from "../../../assets/images/svg/img-base-coins.svg";
import ImgBaseNft from "../../../assets/images/svg/img-base-nft.svg";
import ImgBaseTshirts from "../../../assets/images/svg/img-base-tshirt.svg";

export enum GiftColor {
    Purple = 'purple',
    Green = 'green',
    Blue = 'blue',
    Orange = 'orange',
}

export const getGiftColor = (gType?: GiftItem['type']): GiftColor => {
    switch (gType) {
        case GiftTypeEnum.Coins:
        case GiftTypeEnum.Altcoins:
            return GiftColor.Blue;
        case GiftTypeEnum.Merch:
            return GiftColor.Orange;
        case GiftTypeEnum.Nft:
            return GiftColor.Purple;
        default:
            return GiftColor.Green;
    }
};

export const getGiftPic = (type: GetLootBoxListItem['gifts'][number]['type']) => {
    switch (type) {
        case GiftTypeEnum.Coins:
        case GiftTypeEnum.Altcoins:
            return ImgBaseCoins;
        case GiftTypeEnum.Nft:
            return ImgBaseNft;
        case GiftTypeEnum.Merch:
            return ImgBaseTshirts;
    }
};

export type GiftSize = 'giant' | 'big' | 'medium' | 'small';
export const getGiftSize = (index: number, isDesktop = false): GiftSize => {
    if (index === 0) return 'big';
    if (isDesktop && index < 4 || !isDesktop && index < 3) {
        return 'medium';
    }

    return 'small'
}