import React, { FC, ReactNode, useState } from 'react';
import { App, Button, Card, Tooltip } from 'antd';
import { WagmiConnectorsIds } from 'src/utils/wagmi/config';
import WalletConnectLogoSvg from 'src/assets/images/svg/wallet-connect-logo.svg';
import MetaMaskLogoSvg from 'src/assets/images/svg/metamask-logo.svg';
import CoinBaseLogoSvg from 'src/assets/images/svg/coinbase-logo.svg';
import { Space } from 'src/components/ant/space';
import { Typography } from 'src/components/ant/typography';
import { Connector, useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import {useTranslation} from "react-i18next";
import {useLootboxStore} from "../../store";

export const wagmiConnectorsIconMap: Record<WagmiConnectorsIds, string> = {
  [WagmiConnectorsIds.MetaMask]: MetaMaskLogoSvg,
  [WagmiConnectorsIds.WalletConnect]: WalletConnectLogoSvg,
  [WagmiConnectorsIds.CoinbaseWallet]: CoinBaseLogoSvg,
};

export const ConnectWalletWidget: FC = () => {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();
  const { connectors, isLoading, pendingConnector, connectAsync } = useConnect();
  const { message } = App.useApp();
  const { user } = useLootboxStore();
  const { disconnect } = useDisconnect();
  const [isSignAwaiting, setIsSignAwaiting] = useState(false);

  const { signMessageAsync } = useSignMessage({
    message: 'Alloca',
  });

  const onAddWallet = async (connector: Connector) => {
    try {
      if (user) {
        await connectAsync({ connector });
        setIsSignAwaiting(true);
        await signMessageAsync();
        setIsSignAwaiting(false);
      }
    } catch (e) {
      // @ts-ignore
      message.error(e.message);
      disconnect();
    }
  };

  return (
    <Card bodyStyle={{ padding: '16px 24px' }}>
      <Space size={12} direction="vertical" full>
        <Typography.Text size="middle" style={{ fontWeight: '600' }}>
          {isConnected && !isSignAwaiting ? t('CONNECT_WALLET_TITLE_CONNECTED') : t('CONNECT_WALLET_TITLE')}
        </Typography.Text>
        {!isConnected ? (
          <Space size={4} direction="horizontal" wrap>
            {connectors.map((connector: any) => (
              <Tooltip
                key={connector.id}
                placement="bottom"
                title={!connector.ready && t('CONNECT_WALLET_NOT_ALLOWED')}
              >
                <Button
                  style={{
                    border: 'none',
                    color: '#52ff00',
                    backgroundColor: 'transparent',
                    opacity: !connector.ready ? '0.5' : '1',
                    boxShadow: 'none',
                  }}
                  loading={isLoading && connector.id === pendingConnector?.id}
                  disabled={!connector.ready}
                  onClick={async () => await onAddWallet(connector)}
                >
                  <img src={wagmiConnectorsIconMap[connector.id as WagmiConnectorsIds]} alt=""/>
                  <Typography.Text size="middle" style={{ color: '#52ff00' }}>
                    {connector.name}
                  </Typography.Text>
                </Button>
              </Tooltip>
            ))}
          </Space>
        ) : (
          <Space size={12} direction="vertical">
            <Typography.Text>{address}</Typography.Text>
            {isSignAwaiting && <Typography.Text>{t('CONNECT_WALLET_SIGN_MESSAGE')}</Typography.Text>}
            {!isSignAwaiting && (
              <Button block size="small" type="link" onClick={() => disconnect()}>
                {t('CONNECT_WALLET_BUTTON_DISCONNECT')}
              </Button>
            )}
          </Space>
        )}
      </Space>
    </Card>
  );
};
