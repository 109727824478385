import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, DatePicker, Flex, Table, TableColumnsType } from 'antd';
import { Typography } from 'src/components/ant/typography';
import ErrorImage from 'src/assets/images/svg/error.svg';
import CheckIcon from 'src/assets/images/svg/check.svg';
import locale from 'antd/es/date-picker/locale/ru_RU';

import styles from './history.module.css';
import { TagsFilter } from '../../components/tags-filter/TagsFilter';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { useTranslation } from 'react-i18next';
import { HistoryStatus, HistoryTransactionsItem } from '../../api/history';
import { useLootboxStore } from '../../store';
import { formatNumber } from '../../utils/formatters';
import { useMediaQuery } from '../../hooks/useMediaQuery';
const { RangePicker } = DatePicker;

const statusFilters = [
  {
    title: 'Все',
    value: HistoryStatus.All,
  },
  {
    title: 'Выполнено',
    value: HistoryStatus.Done,
  },
  {
    title: 'Ошибка при оплате',
    value: HistoryStatus.Error,
  },
];

export const HistoryContainer = () => {
  const { t } = useTranslation();
  const isSmallMobile = useMediaQuery('(max-width: 575px)');
  const { getHistoryTransactions, history } = useLootboxStore();
  const [filterStatus, setFilterStatus] = useState<HistoryStatus | undefined>(HistoryStatus.All);
  const [filterDate, setFilterDate] = useState<string | null>(null);

  const ruLocale = useMemo<PickerLocale>(
    () => ({
      ...locale,
      lang: {
        ...locale.lang,
        rangePlaceholder: [t('Дата начала'), t('Дата конца')],
      },
    }),
    [t]
  );

  const list = useMemo(() => {
    if (filterStatus === HistoryStatus.All) return history;

    return history.filter(({ status }) => status === filterStatus);
  }, [filterStatus, history.length]);

  useEffect(() => {
    getHistoryTransactions({});
  }, []);

  return (
    <div className={styles.container}>
      <Card bordered className={styles.card} styles={{ body: { padding: 0 } }}>
        <Flex justify="space-between" align="center" className={styles.header}>
          <Typography.Title className={styles.title}>Фильтры</Typography.Title>
          <Typography.Text type="secondary" size="small" className={styles.cleaner}>
            Очистить
          </Typography.Text>
        </Flex>

        <Flex justify="space-between" align="center" wrap gap={12}>
          <TagsFilter<HistoryStatus>
            className={styles.filterWrapper}
            title="По статусу:"
            onClick={setFilterStatus}
            list={statusFilters}
            active={filterStatus}
            isMobile={isSmallMobile}
          />

          <Flex align="center" justify="flex-start">
            <Typography.Text size="middle" className={styles.filterDate} strong>
              По дате:
            </Typography.Text>

            <RangePicker
              locale={ruLocale}
              placeholder={['Начало', 'Конец']}
              placement="topLeft"
              format="DD.MM.YYYY"
            />
            {/*<DatePicker<string>*/}
            {/*  locale={ruLocale}*/}
            {/*  placeholder="Укажите дату"*/}
            {/*  placement='topLeft'*/}
            {/*  format="DD.MM.YYYY"*/}
            {/*  value={filterDate}*/}
            {/*  onChange={setFilterDate}*/}
            {/*  className="ant-picker-small-padding ant-picker-huge ant-input-left-align"*/}
            {/*  size={"small"}*/}
            {/*/>*/}
          </Flex>
        </Flex>
      </Card>

      <Table
        className={styles.table}
        columns={columns(isSmallMobile)}
        dataSource={list}
        rowKey="id"
        pagination={{ position: ['bottomCenter'] }}
        size={isSmallMobile ? 'small' : 'middle'}
      />
    </div>
  );
};

const columns = (isMobile: boolean): TableColumnsType<HistoryTransactionsItem> => [
  {
    title: 'Сумма',
    dataIndex: 'volume',
    render: (value, record) => (
      <>
        {isMobile ? <div className={styles.mobileDate}>{record.created_at}</div> : null}
        <span className={styles.mobileVolume}>{formatNumber(value)} USDT</span>
      </>
    ),
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    align: 'right',
    hidden: isMobile,
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updated_at',
    align: 'right',
    hidden: isMobile,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    align: 'right',
    render: (value) => {
      if (value === HistoryStatus.Done) {
        return (
          <Button size={isMobile ? 'small' : 'middle'} className={styles.button} disabled>
            <img src={CheckIcon} alt={value} /> Выполнено
          </Button>
        );
      }
      return (
        <Button size={isMobile ? 'small' : 'middle'} className={styles.button} disabled danger>
          <img src={ErrorImage} alt={value} />
          Ошибка при оплате
        </Button>
      );
    },
  },
];
