import React from 'react';
import { Button, Card } from 'antd';
import WalletIcon from 'src/assets/images/svg/wallet.svg';
import WalletGreenIcon from 'src/assets/images/svg/wallet-green.svg';
import { Typography } from 'src/components/ant/typography';
import { useMediaQuery } from 'src/hooks/useMediaQuery';

import styles from './balanceBlock.module.css';
import { UserModel } from '../../../api/user';
import TgLogo from 'src/assets/images/svg/telegram-green-logo.svg';

const ICON_PROPS = (isSmallMobile: boolean) => ({
  width: isSmallMobile ? 16 : 24,
  height: isSmallMobile ? 16 : 24,
});

type Props = {
  user: UserModel | null;
  handleLogin: () => void;
  setIsOpenDepositFlowModal: (is: boolean) => void;
};

export const BalanceBlock = ({ user, handleLogin, setIsOpenDepositFlowModal }: Props) => {
  const isSmallMobile = useMediaQuery('(max-width: 575px)');
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(max-width: 991px) and (min-width: 768px)');

  const handleOpenModal = () => setIsOpenDepositFlowModal(true);

  if (!user?.id) {
    return (
      <Card bordered className={styles.card} styles={{ body: { padding: '0' } }}>
        <div className={styles.container}>
          {isSmallMobile ? null : <img src={WalletIcon} {...ICON_PROPS(isSmallMobile)} alt="wallet-icon" />}
          <Typography.Title level={3} className={styles.text}>
            <span>Баланс: </span>
            {user?.attemptBalance || 0.00}
            <Typography.Title level={3} type="secondary">
              USDT
            </Typography.Title>
          </Typography.Title>

          <Button size="small" className={styles.button} onClick={handleOpenModal}>
            {isSmallMobile ? (
              <img src={WalletGreenIcon} {...ICON_PROPS(isSmallMobile)} alt="wallet-icon" />
            ) : (
              'Пополнить'
            )}
          </Button>
        </div>
      </Card>
    );
  }

  return (
    <Button size="small" className={styles.button} onClick={handleLogin}>
      <img src={TgLogo} alt="" />
      {isTablet || isMobile ? 'Войти' : 'Войти через Telegram'}
    </Button>
  );
};
