import FiveBucksIcon from 'src/assets/images/png/5_bucks.png';
import TenBucksIcon from 'src/assets/images/png/10_bucks.png';
import TwentyBucksIcon from 'src/assets/images/png/20_bucks.png';

export enum DepositAmount {
  FiveBucks = 'FIVE',
  TenBucks = 'TEN',
  TwentyBucks = 'TWENTY'
}

type DepositModal = Record<DepositAmount, {
  icon: string;
  amount: number;
  attempts: number;
}>
export const DEPOSIT_INFO_MODAL: DepositModal = {
  [DepositAmount.FiveBucks]: {
    icon: FiveBucksIcon,
    amount: 5,
    attempts: 10,
  },
  [DepositAmount.TenBucks]: {
    icon: TenBucksIcon,
    amount: 10,
    attempts: 20,
  },
  [DepositAmount.TwentyBucks]: {
    icon: TwentyBucksIcon,
    amount: 20,
    attempts: 30,
  },
}