import { create } from 'zustand';
import Cookies from "js-cookie";
import {getLootboxesApi, GetLootBoxListItem, openLootboxApi} from '../api/cases';
import {getUserInfoApi, telegramCallbackApi, TelegramUserModel, UserModel} from '../api/user';
import {getGiftsByUserApi, GiftListItemModel, withdrawGiftApi} from "../api/gift";
import {mockTable} from "../pages/win/mock";
import {getHistoryTransactionsApi, HistoryFilters, HistoryTransactionsItem} from "../api/history";
import {historyMockTable} from "../pages/history/mock";
import {getWalletsApi, LootboxWalletsModel, mockWallets} from "../api/wallets";

interface Actions {
  getUserInfo: () => void;
  getLootboxes: () => void;
  getGifts: () => void;
  getHistoryTransactions: (filters: HistoryFilters) => void;
  openLootbox: (lootboxId: string) => void;
  withdrawGift: (giftId: string, cb: () => void, errCb: () => void) => void;
  telegramCallback: (tgUser: TelegramUserModel) => void;
  getWallets: () => void;
}

interface State {
  lootboxes: GetLootBoxListItem[];
  gifts: GiftListItemModel[];
  history: HistoryTransactionsItem[];
  wallets: LootboxWalletsModel;
  loading: boolean;
  userLoading: boolean;
  opening: boolean;
  user: UserModel | null;
  userId: string;
  sessionID: string;
}

export const useLootboxStore = create<State & Actions>()((set, get) => ({
  userLoading: true,
  loading: true,
  opening: true,
  lootboxes: [],
  gifts: [],
  history: [],
  wallets: { data: { blockchain_wallets: [] }, meta: {}},
  user: null,
  userId: Cookies.get('userID') || '',
  sessionID: Cookies.get('sessionID') || '',
  getLootboxes: () => {
    getLootboxesApi(get().userId)
      .then(({ data: lootboxes }) => {
        set({ lootboxes, loading: false });
      })
      .catch(() => {
        set({ loading: false });
      });
  },
  openLootbox: (lootboxId) => {
    openLootboxApi(get().userId, lootboxId).then(() => {
      setTimeout(() => set({ opening: false }), 1500);
    })
  },
  getUserInfo: () => {
    console.log('get().userId', get().userId)
    getUserInfoApi(get().userId)
      .then(({ data: user }) => {
        set({ user });
        Cookies.set('userID', user.id);
      })
      .finally(() => {
        set({ userLoading: false });
      });
  },
  telegramCallback: (tgUser) => {
    telegramCallbackApi(tgUser)
      .then(({ data: user }) => {
        set({ user, userId: user.id, userLoading: true });
        Cookies.set('userID', user.id);

        get().getUserInfo();
      });
  },
  getGifts: () => {
    getGiftsByUserApi(get().userId)
      .then(({ data: gifts }) => {
        set({ gifts, loading: false });
      })
      .catch(() => {
        // todo delete mock
        set({ loading: false, gifts: mockTable });
      });
  },
  withdrawGift: (id, cb, errCb) => {
    withdrawGiftApi(get().userId, id)
      .then(({ data: gifts }) => {
        set({ gifts, loading: false });
        cb();
      })
      .catch(() => {
        // todo delete mock
        set({ loading: false });
        errCb();
      });
  },
  getHistoryTransactions: (filters: HistoryFilters) => {
    getHistoryTransactionsApi({ userId: get().userId, ...filters })
      .then(({ data: history }) => {
        set({ history, loading: false });
      })
      .catch(() => {
        // todo delete mock
        set({ loading: false, history: historyMockTable });
      });
  },
  getWallets: () => {
    getWalletsApi()
      .then(({ data: wallets }) => {
        set({ wallets });
      })
      .catch(() => {
        // todo delete mock
        set({ wallets: mockWallets });
      });
  }
}));
