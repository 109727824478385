import type { Hex } from 'viem';

export const mainnetErc20Usdt: Hex = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
export const arbitrumUsdt: Hex = '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9';
export const polygonUsdt: Hex = '0xc2132d05d31c914a87c6611c10748aeb04b58e8f';
export const bnbSmartChainBep20Usdt: Hex = '0x55d398326f99059ff775485246999027b3197955';

export const mainnetErc20UsdtUnit = 6;
export const arbitrumUsdtUnit = 6;
export const polygonUsdtUnit = 6;
export const bnbSmartChainBep20UsdtUnit = 18;
