import React from 'react';
import { Card } from 'antd';
import RoulettePro from 'react-roulette-pro';

import styles from './rollerContainer.module.css';
import classNames from 'classnames';
import { GiftItem } from '../../api/cases';
import { GiftItemCard } from '../cases/gift-item';
import { GiftTypeEnum } from '../../api/gift';

type Props = {
    isRunning: boolean;
    onGiftDefined: () => void;
    giftIndex: number;
    gifts: GiftItem[];
};
export const RollerContainer = ({ isRunning, onGiftDefined, giftIndex, gifts }: Props) => {
    const list = [
        ...gifts,
        ...gifts,
        ...gifts,
        ...gifts,
        ...gifts,
        ...gifts,
        ...gifts,
        ...gifts,
        ...gifts,
        ...gifts,
    ].map(({ type, photo }) => ({ id: Math.random(), image: photo, text: type }));

    return (
        <div className={classNames(styles.container, isRunning && styles.active)}>
            <Card>
                <RoulettePro
                    start={isRunning}
                    prizes={list}
                    prizeIndex={giftIndex}
                    spinningTime={6}
                    options={{  withoutAnimation: true }}
                    prizeItemRenderFunction={({ id, text, image }) => (
                        <div className={styles.item}>
                            <GiftItemCard
                                key={id}
                                data={{ type: text as GiftTypeEnum, name: '', photo: image, id: `${id}`, price: 0 }}
                                size={'small'}
                                isRollerView
                            />
                        </div>
                    )}
                    classes={{ wrapper: styles.wrapper }}
                    onPrizeDefined={onGiftDefined}
                />
            </Card>
        </div>
    );
};
