export const formatNumber = (value: number | string, noSeparator = false): string => {
  let formatted = '';
  const v = +value;
  if (!isNaN(v)) {
    if (v && v < 0.000001) {
      const str = v.toFixed(20);
      const index = str.split('').findIndex((c) => c !== ',' && +c >= 1);
      return str.slice(0, index + 4);
    }
    formatted = v.toPrecision(v > 10 ? 4 : 3)

    return noSeparator ? formatted : formatted.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }
  return ''
}