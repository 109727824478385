import React from 'react';
import styles from './index.module.css';

type Props = {
  volume: number;
};
export const TotalContent = ({ volume }: Props) => (
  <div className={styles.totalWrapper}>
    <div>Итого:</div>
    <div>
      <span>{volume}</span> USDT
    </div>
  </div>
);

