import React from 'react';
import styles from './index.module.css';
import {TotalContent} from "./total-content";
import WebImg from "../../../assets/images/svg/web.svg";
import WalletImg from "../../../assets/images/svg/wallet-payment.svg";

type Props = {
  goToQrPayment: () => void;
  goToWalletPayment: () => void;
  volume: number;
};

export const ChoosePaymentWayContent = ({ goToQrPayment,
                                          goToWalletPayment, volume }: Props) => {
  return (
    <>
      <TotalContent volume={volume} />
      <div className={styles.chooseButtonsWrapper}>
        <div className={styles.button} onClick={goToQrPayment}>
          <div className={styles.buttonImg}>
            <img src={WebImg} alt="" />
          </div>
          <div className={styles.buttonText}>
            <div>Оплатить через сеть</div>
            <div>ERC20, BEP20, Arbitrum, Polygon</div>
          </div>
        </div>
        <div className={styles.button} onClick={goToWalletPayment}>
          <div className={styles.buttonImg}>
            <img src={WalletImg} alt="" />
          </div>
          <div className={styles.buttonText}>
            <div>Оплатить привязанным кошельком</div>
            <div>MetaMask, Wallet Connect</div>
          </div>
        </div>
      </div>
    </>
  );
};
