import api from 'src/api/index';
import CaseImage from 'src/assets/images/png/case_1.png';
import NftBarrelImage from 'src/assets/images/png/nft-barrel.png';
import NftCollectionImage from 'src/assets/images/png/nft-collection.png';
import BitcoinImage from 'src/assets/images/png/bitcoin.png';
import SolanaImage from 'src/assets/images/png/solana.png';
import CardanoImage from 'src/assets/images/png/cardano.png';
import CapImage from 'src/assets/images/png/cap.png';
import TShirtImage from 'src/assets/images/png/tshirt.png';
import { GiftTypeEnum } from './gift';

export type GiftItem = {
  id: string;
  name: string;
  photo: string;
  price: number;
  type: GiftTypeEnum;
};
export type GetLootBoxListItem = {
  id: string;
  name: string;
  photo: string;
  type: string;
  attempt_balance: number;
  gifts: GiftItem[];
};

export const getLootboxesApi = (user_id: string) =>
  api.get<GetLootBoxListItem[]>('partner/lootbox/lootbox_list', { params: { user_id } });

export const openLootboxApi = (user_id: string, lootbox_id: string) =>
  api.post('partner/lootbox/lootbox_open', { user_id, lootbox_id }, { params: { user_id } });

export const mockCases: GetLootBoxListItem[] = [
  {
    id: '1',
    name: 'Rainbow Drop',
    photo: CaseImage,
    type: '',
    attempt_balance: 10,
    gifts: [
      { id: '11', name: 'NFT Barrel', photo: NftBarrelImage, price: 1, type: GiftTypeEnum.Nft },
      { id: '12', name: 'Коллекция NFT Author', photo: NftCollectionImage, price: 1, type: GiftTypeEnum.Nft },
      { id: '13', name: 'BTC', photo: BitcoinImage, price: 1, type: GiftTypeEnum.Coins },
      { id: '14', name: 'Solana', photo: SolanaImage, price: 1, type: GiftTypeEnum.Altcoins },
      { id: '15', name: 'Cardano', photo: CardanoImage, price: 1, type: GiftTypeEnum.Altcoins },
      { id: '16', name: 'Alloca CAP', photo: CapImage, price: 1, type: GiftTypeEnum.Merch },
      { id: '17', name: 'Alloca T-SHIRT', photo: TShirtImage, price: 1, type: GiftTypeEnum.Merch },
      { id: '18', name: 'Test1', photo: BitcoinImage, price: 1, type: GiftTypeEnum.Merch },
      { id: '19', name: 'Test2', photo: CardanoImage, price: 1, type: GiftTypeEnum.Nft },
      { id: '181', name: 'Test3', photo: NftBarrelImage, price: 1, type: GiftTypeEnum.Merch },
    ],
  },
  {
    id: '2',
    name: 'New another Drop',
    photo: CaseImage,
    type: '',
    attempt_balance: 5,
    gifts: [
      { id: '171', name: 'Alloca T-SHIRT', photo: TShirtImage, price: 1, type: GiftTypeEnum.Merch },
      { id: '161', name: 'Alloca CAP', photo: CapImage, price: 1, type: GiftTypeEnum.Merch },
      { id: '141', name: 'Solana', photo: SolanaImage, price: 1, type: GiftTypeEnum.Altcoins },
      { id: '131', name: 'BTC', photo: BitcoinImage, price: 1, type: GiftTypeEnum.Coins },
      { id: '151', name: 'Cardano', photo: CardanoImage, price: 1, type: GiftTypeEnum.Altcoins },
      { id: '121', name: 'Коллекция NFT Author', photo: NftCollectionImage, price: 1, type: GiftTypeEnum.Nft },
      { id: '111', name: 'NFT Barrel', photo: NftBarrelImage, price: 1, type: GiftTypeEnum.Nft },
    ],
  },
];
