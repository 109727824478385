import api from './index';

export enum GiftTypeEnum {
  Coins = 'COINS',
  Altcoins = 'ALTCOINS',
  Nft = 'NFT',
  Merch = 'MERCH',
}
export enum StatusWin {
  New = 'NEW',
  Completed = 'COMPLETED',
}

export type GiftListItemModel = {
    id: string;
    name: string;
    type: GiftTypeEnum;
    photo_id: string;
    received_at: string;
    status?: StatusWin;
    currency: string;
    price: number;
}

export const getGiftsByUserApi = (user_id: string) =>
  api.get<GiftListItemModel[]>('partner/gifts/gifts_by_filter', { params: { user_id } });

export const withdrawGiftApi = (user_id: string, gift_id: string) =>
  api.post('partner/gifts/update_user_gift', { gift_id });
