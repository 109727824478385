import { HistoryStatus, HistoryTransactionsItem } from '../../api/history';

export const historyMockTable: HistoryTransactionsItem[] = [
  {
    id: '1',
    status: HistoryStatus.Done,
    volume: 15,
    created_at: '01.01.2024 15:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '2',
    status: HistoryStatus.Done,
    volume: 5,
    created_at: '02.01.2024 05:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '3',
    status: HistoryStatus.Error,
    volume: 10,
    created_at: '04.02.2024 11:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '4',
    status: HistoryStatus.Done,
    volume: 25,
    created_at: '01.05.2024 12:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '5',
    status: HistoryStatus.Error,
    volume: 50,
    created_at: '04.05.2024 10:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '6',
    status: HistoryStatus.Done,
    volume: 15,
    created_at: '11.05.2024 20:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '7',
    status: HistoryStatus.Done,
    volume: 10,
    created_at: '01.06.2024 18:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '8',
    status: HistoryStatus.Error,
    volume: 15,
    created_at: '10.06.2024 19:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '9',
    status: HistoryStatus.Done,
    volume: 5,
    created_at: '20.07.2024 21:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '10',
    status: HistoryStatus.Done,
    volume: 10,
    created_at: '15.08.2024 11:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '11',
    status: HistoryStatus.Error,
    volume: 30,
    created_at: '16.08.2024 06:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '12',
    status: HistoryStatus.Done,
    volume: 50,
    created_at: '17.08.2024 09:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '13',
    status: HistoryStatus.Done,
    volume: 15,
    created_at: '01.09.2024 22:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '14',
    status: HistoryStatus.Error,
    volume: 20,
    created_at: '04.09.2024 22:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
  {
    id: '15',
    status: HistoryStatus.Done,
    volume: 25,
    created_at: '08.09.2024 09:00',
    blockchain_id: '',
    hash: 'dfg3433242k34m 2k3m42',
    updated_at: '09.09.2024 12:00',
    user_address: '0x3nknj23jn42k3j4n2kj3n4k234',
    user_id: 'user_id'
  },
];
