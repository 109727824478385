import React, { useEffect, useMemo, useState } from 'react';
import { message, Button, Card, Flex, Table } from 'antd';
import WarningIcon from 'src/assets/images/svg/warning.svg';
import CheckIcon from 'src/assets/images/svg/check.svg';
import { Typography } from 'src/components/ant/typography';
import type { TableColumnsType } from 'antd';

import styles from './win.module.css';
import { GiftListItemModel, StatusWin } from '../../api/gift';
import { useLootboxStore } from '../../store';
import { ModalWithdrawRequest } from '../../components/modals/modal-withdraw-request';
import {useMediaQuery} from "../../hooks/useMediaQuery";

export const WinContainer = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { getGifts, gifts, withdrawGift } = useLootboxStore();
  const isSmallMobile = useMediaQuery('(max-width: 575px)');
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);

  const handleWithdraw = (id: string) => {
    withdrawGift(
      id,
      () => setIsOpenRequestModal(true),
      () => messageApi.open({ content: 'Ошибка! Попробуйте еще раз.', type: "error"})
    );
  };

  const columns: TableColumnsType<GiftListItemModel> = useMemo(
    () => [
      {
        title: 'Название',
        dataIndex: 'name',
        render: (value, record) => (
          <Flex gap="small" justify="flex-start" align="center">
            <img src={record.photo_id} width={isSmallMobile? 36: 44} height={isSmallMobile? 36: 44} />
            <span className={styles.mobileValue}>{value}</span>
          </Flex>
        ),
      },
      {
        title: 'Дата получения',
        dataIndex: 'received_at',
        render: value => <span className={styles.mobileValue}>{value}</span>
      },
      {
        title: '',
        dataIndex: 'status',
        render: (value, record) => {
          if (value === StatusWin.New) {
            return (
              <Button type="primary" className={styles.statusButton} size={isSmallMobile ? 'small' : 'middle'} onClick={() => handleWithdraw(record.id)}>
                Вывести
              </Button>
            );
          }
          return (
            <Button disabled className={styles.statusButton} size={isSmallMobile ? 'small' : 'middle'}>
              <img src={CheckIcon} alt={value} />
              Выведен
            </Button>
          );
        },
      },
    ],
    [isSmallMobile]
  );

  useEffect(() => {
    getGifts();
  }, []);
  return (
    <div>
      {contextHolder}
      <Card bordered styles={{ body: { padding: '16px 24px' } }} className={styles.card}>
        <div className={styles.container}>
          <img src={WarningIcon} />
          <Typography.Text className={styles.text} size="middle">
            Наш менеджер свяжется с вами для уточнения деталей в течении 24 часов, после выставления заявки
          </Typography.Text>
        </div>
      </Card>

      <Table
        className={styles.table}
        columns={columns}
        dataSource={gifts}
        rowKey="id"
        pagination={{ position: ['bottomCenter'] }}
        size={isSmallMobile ? 'small' : 'middle'}
      />
      {isOpenRequestModal ? <ModalWithdrawRequest onClose={() => setIsOpenRequestModal(false)} /> : null}
    </div>
  );
};
