import React, { FC } from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
import { GiftItem } from '../../../api/cases';
import { getGiftColor, getGiftPic, GiftSize } from './getData';

type Props = {
    data: GiftItem;
    size: GiftSize;
    isRollerView?: boolean;
};

export const GiftItemCard: FC<Props> = ({ data: { name, photo, type }, size = 'small', isRollerView = false }) => {
    return (
        <div
            className={classNames(
                styles.gift,
                styles[size],
                styles[getGiftColor(type)],
                isRollerView && styles.rollerView
            )}
        >
            <div className={styles.gradient}></div>
            <div className={styles.imgWrapper}>
                <img className={styles.imgBase} src={getGiftPic(type)} alt="" />
                <img className={styles.img} src={photo} alt="" />
            </div>
            <div className={styles.name}>{name}</div>
        </div>
    );
};
