import Lottie from 'lottie-react';
import React, { FC } from 'react';
import anim1 from '../../assets/animations/lootbox-open.json';

type Props = {
  onOpenLoot: () => void;
};
export const LootboxOpen: FC<Props> = ({ onOpenLoot }) => {
  return (
    <div style={{ position: "absolute" }}>
      <Lottie animationData={anim1} autoplay={true} loop={false} onPlay={onOpenLoot} />
    </div>
  );
};
