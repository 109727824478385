import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { WagmiConfig } from 'wagmi';
import { themeVariables } from './assets/theme';
import { PagesEnum, REACT_APP_TELEGRAM_BOT_ID } from './constants';
import { CasesContainer } from './pages/cases';
import { Header } from './components/header/Header';
import { HistoryContainer } from './pages/history/History';
import { WinContainer } from './pages/win/Win';
import 'react-roulette-pro/dist/index.css';
import { useLootboxStore } from './store';
import { Spinner } from './components/spinner';
import { ModalTelegram } from './components/modals/modal-telegram';
import { TelegramUserModel } from './api/user';
import { ModalNoMoney } from './components/modals/modal-no-money';
import { ModalDeposit } from './components/modals/modal-deposit/DepositModal';
import './assets/scss/antd-rewrite.scss';
import './index.scss';
import { ModalPayment } from './components/modals/modal-payment';
import { ConnectedWalletPaymentModal } from './components/modals/modal-payment/wagmi-payments/ConnectedWalletPaymentModal';
import { config } from './utils/wagmi/config';
import {ModalDepositSuccess} from "./components/modals/modal-deposit-success";

function App() {
  const [amount, setAmount] = useState<number>(10);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenWarningDepositModal, setIsOpenWarningDepositModal] = useState(false);
  const [isOpenDepositFlowModal, setIsOpenDepositFlowModal] = useState(false);
  const [isOpenCommonPaymentModal, setIsOpenCommonPaymentModal] = useState(false);
  const [isOpenWalletPaymentModal, setIsOpenWalletPaymentModal] = useState(false);
  const [isOpenDepositSuccessModal, setIsOpenDepositSuccessModal] = useState(false);
  const { userLoading, getUserInfo, user, userId, telegramCallback } = useLootboxStore();
  const handleOpenLoginModal = () => {
    setIsOpenLoginModal(true);
  };
  const handleOpenWarningDepositModal = () => {
    setIsOpenWarningDepositModal(true);
  };

  const handleLogin = () => {
    try {
      window.Telegram?.Login.auth(
        {
          bot_id: REACT_APP_TELEGRAM_BOT_ID,
          request_access: true,
        },
        async (data) => {
          if (!data) {
            prompt('Error with telegram auth, no data');
            return;
          }

          await onTelegramAuth(data);
        }
      );
    } catch (error) {
      prompt(`${error}`);
      console.error('error with telegram auth', error);
    }
  };

  const onTelegramAuth = async (telegramUser: TelegramUserModel) => {
    try {
      telegramCallback(telegramUser);
    } catch (error) {
      prompt(`${error}`);
      // prompt('Error with telegram auth, no data');
    }
  };

  const handleOpenDepositFlowModal = () => {
    setIsOpenWarningDepositModal(false);
    setIsOpenDepositFlowModal(true);
  };

  const goToWalletPayment = () => {
    setIsOpenWalletPaymentModal(true);
  };

  const onSubmitAmountPayment = (amount: number) => {
    setAmount(amount);
    setIsOpenDepositFlowModal(false);
    setIsOpenCommonPaymentModal(true);
  }

  const onSubmitFinalPayment = (hash: string) => {
    console.log('hash', hash);
    setIsOpenDepositSuccessModal(true);
  }

  const closeAllModals = () => {
    setIsOpenLoginModal(false);
    setIsOpenWarningDepositModal(false);
    setIsOpenDepositFlowModal(false);
    setIsOpenCommonPaymentModal(false);
    setIsOpenWalletPaymentModal(false);
    setIsOpenDepositSuccessModal(false);
  }

  useEffect(() => {
    getUserInfo();
  }, [userId]);

  useEffect(() => {
    const telegramScript = document.createElement('script');
    telegramScript.async = true;
    telegramScript.src = 'https://telegram.org/js/telegram-widget.js?22';

    document.body.appendChild(telegramScript);
  }, []);

  if (userLoading) {
    return (
      <div
        style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="app">
      <ConfigProvider theme={{ token: themeVariables }}>
        <Header handleLogin={handleLogin} setIsOpenDepositFlowModal={setIsOpenDepositFlowModal} />
        <Routes>
          <Route
            path={`${PagesEnum.Cases}`}
            element={
              <CasesContainer
                handleOpenLoginModal={handleOpenLoginModal}
                handleOpenWarningDepositModal={handleOpenWarningDepositModal}
              />
            }
          />
          <Route path={`${PagesEnum.History}`} element={<HistoryContainer />} />
          <Route path={`${PagesEnum.Win}`} element={<WinContainer />} />
        </Routes>
        {isOpenLoginModal ? (
          <ModalTelegram onClose={() => setIsOpenLoginModal(false)} handleLogin={handleLogin} />
        ) : null}
        {isOpenWarningDepositModal ? (
          <ModalNoMoney
            onClose={() => setIsOpenWarningDepositModal(false)}
            handleOpenDepositFlowModal={handleOpenDepositFlowModal}
          />
        ) : null}
        {isOpenDepositFlowModal ? (
          <ModalDeposit
            onClose={() => setIsOpenDepositFlowModal(false)}
            onSubmit={onSubmitAmountPayment}
          />
        ) : null}
        {isOpenCommonPaymentModal ? (
          <ModalPayment
            volume={amount}
            onClose={() => setIsOpenDepositFlowModal(false)}
            goToWalletPayment={goToWalletPayment}
            onSubmitQrPayment={onSubmitFinalPayment}
          />
        ) : null}
        <WagmiConfig config={config}>
          {isOpenWalletPaymentModal ? (
            <ConnectedWalletPaymentModal onCancel={() => setIsOpenDepositFlowModal(false)} volume={amount} onSubmitFinalPayment={onSubmitFinalPayment} />
          ) : null}
        </WagmiConfig>
        { isOpenDepositSuccessModal ? (
          <ModalDepositSuccess onClose={closeAllModals} />
        ) : null }
      </ConfigProvider>
    </div>
  );
}

export default App;
