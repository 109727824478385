import React from 'react';
import { Button, Modal } from 'antd';
import styles from './index.module.css';
import TgLogo from "src/assets/images/svg/telegram-black-logo.svg";
import LoginImg from "src/assets/images/png/login.png";

type Props = {
  onClose: () => void;
  handleLogin: () => void;
};
export const ModalTelegram = (props: Props) => {
  const { onClose, handleLogin } = props;
  return (
    <Modal open footer={null} onCancel={onClose} className={styles.modal}>
      <div className={styles.gradient}></div>
      <img className={styles.logo} src={LoginImg} alt=""/>
      <div className={styles.title}>Войдите в аккаунт</div>
      <div className={styles.subtitle}>
        Войдите в аккаунт и пополните баланс для того чтобы открыть лутбокс.
      </div>
      <Button className={styles.button} type={"primary"} onClick={handleLogin}>
        <img src={TgLogo} alt=""/>
        Войти через Telegram
      </Button>
    </Modal>
  );
};
