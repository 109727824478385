import React, {useState} from 'react';
import {Typography} from 'src/components/ant/typography';
import {Button, Flex, Form, Modal, Radio} from "antd";

import styles from './depositModal.module.css';
import classNames from "classnames";
import {ManualDepositForm} from "./manual-form/ManualDepositForm";
import {DEPOSIT_INFO_MODAL, DepositAmount} from "./constants";

type Props = {
  onClose: () => void;
  onSubmit: (amount: number) => void;
};

export const ModalDeposit = ({ onClose, onSubmit }: Props) => {
  const [selectedAmount, setSelectedAmount] = useState(DepositAmount.TenBucks);
  const [isOpenManualAmount, setIsOpenManualAmount] = useState<boolean>(false);

  const handleSubmit = () => {
    onSubmit(DEPOSIT_INFO_MODAL[selectedAmount].amount)
  }

  const handleOpenManualAmount = () => setIsOpenManualAmount(true);
  const handleCloseManualAmount = () => setIsOpenManualAmount(false);

  return (
    <>
      <Modal
        open={!isOpenManualAmount}
        footer={null}
        onCancel={onClose}
        className={styles.modal}
      >
        <Form onFinish={handleSubmit}>
          <Flex justify='center' align='center' vertical>
            <Typography.Title style={{margin: '24px 0'}}>
              Выберите пакет
            </Typography.Title>

            <div className={styles.secondaryText}>
              Обратите внимание, что баланс будет пополнен в течении 24 часов
            </div>
          </Flex>

            {Object.values(DepositAmount).map((item) => {
              const isSelected = selectedAmount === item;

              return (
                <div
                  key={item}
                  className={classNames(styles.radio, { [styles.radioActive]: isSelected } )}
                  onClick={() => setSelectedAmount(item)}
                >
                  <div className={styles.itemContent}>
                    <img src={DEPOSIT_INFO_MODAL[item].icon} width={64} height={64} alt='amount' />
                    <div>
                      <div className={styles.itemContentTitle}>
                        {DEPOSIT_INFO_MODAL[item].amount} USDT
                      </div>

                      <div className={styles.itemContentSubtitle}>
                        Примерно {DEPOSIT_INFO_MODAL[item].attempts} попыток
                      </div>
                    </div>
                  </div>

                  <Radio checked={isSelected} />
                </div>
              );
            })}

          <div className={styles.footer}>
            <Button type='primary' size='large' block disabled={!selectedAmount} htmlType='submit'>Оплатить</Button>
            <Button type='text' style={{ color: '#52FF00' }} block onClick={handleOpenManualAmount}>Ввести сумму вручную</Button>
          </div>
        </Form>
      </Modal>

      {isOpenManualAmount && <ManualDepositForm onClose={handleCloseManualAmount} onSubmit={onSubmit} />}
    </>
  );
};
