export enum PagesEnum {
  Cases = '/',
  History = 'history',
  Win = 'win',
}

export const DOMAIN = '/';

export const REACT_APP_TELEGRAM_BOT_ID = process.env.REACT_APP_TELEGRAM_BOT_ID || '';
export const REACT_APP_ALCHEMY_PUBLIC_KEY = process.env.REACT_APP_ALCHEMY_PUBLIC_KEY || '';
export const REACT_APP_WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '';
