import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { App, Button, Modal, Select } from 'antd';
import { Space } from 'src/components/ant/space';
import {type Address, parseUnits} from 'viem';

import { useWalletClient, WalletClient, useSwitchNetwork } from 'wagmi';
import { ContractArgs, contractsConfig } from 'src/utils/wagmi/contracts';
import {uniqBy} from "../../../../utils/uniqBy";
import {chainIcons} from "../../../../utils/wagmi/icons";
import styles from "../index.module.css";
import {useLootboxStore} from "../../../../store";
import {UserModel} from "../../../../api/user";
import {ConnectWalletModal} from "./ConnectWalletModal";
import {useTranslation} from "react-i18next";

interface OnWriteContract {
  walletClient: WalletClient;
  value: number;
  user: UserModel;
}

interface Props {
  onCancel?: () => void;
  onSubmitFinalPayment: (hash: string) => void;
  volume: number;
}

type ContractsByChain = Record<number, ContractArgs>;

export const ConnectedWalletPaymentModal: FC<Props> = (props) => {
  const { onCancel, volume, onSubmitFinalPayment } = props;
  // const { t } = useTranslation();
  const { message } = App.useApp();
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);

  const { switchNetworkAsync } = useSwitchNetwork();

  const {
    wallets: { data: walletsData }, user
  } = useLootboxStore();

  const {
    data: walletClient,
    isFetchedAfterMount,
    isFetching,
    isError
  } = useWalletClient();

  const [currentNetwork, setCurrentNetwork] = useState<WalletClient['chain']['id']>(
    walletClient?.chain.id || 1,
  );

  useEffect(() => {
    if (!isFetchedAfterMount && !isFetching) {
      setIsConnectModalOpen(true);
    }
  }, [walletClient, isFetchedAfterMount]);

  useEffect(() => {
    if (walletClient?.chain.id) {
      setCurrentNetwork(walletClient.chain.id);
    }
  }, [walletClient]);

  const contracts = useMemo<ContractsByChain>(() => {
    const availableAddresses: ContractsByChain = {};

    walletsData?.blockchain_wallets.forEach((available) => {
      availableAddresses[available.global_id] = {
        ...contractsConfig[available.global_id],
        recipient: available.wallet_address as Address,
      };
    });

    return availableAddresses;
  }, [walletsData?.blockchain_wallets, walletClient]);

  const onWriteContract = useCallback(
    async ({ walletClient, value }: OnWriteContract) => {
      return await walletClient.writeContract({
        address: contracts[currentNetwork].address,
        account: walletClient.account,
        abi: contracts[currentNetwork].abi,
        functionName: 'transfer',
        args: [
          contracts[currentNetwork].recipient,
          parseUnits(value.toString(), contracts[currentNetwork].units),
        ],
      });
    },
    [contracts, currentNetwork],
  );

  const onPay = useCallback(async () => {
    try {
      if (user) {
        if (!walletClient) {
          setIsConnectModalOpen(true);
        } else {
          // const baseTransactionParams: BaseUpdateTransactionParams = {
          //   transactionId: id,
          //   userId: user.id,
          // };

          const hash = await onWriteContract({
            user,
            walletClient,
            value: volume,
          });

          // await updateTransactionRequest({
          //   ...baseTransactionParams,
          //   hash,
          //   status: TransactionStatus.Confirmed,
          // });

          console.log('hash', hash);
          onSubmitFinalPayment(hash);


          message.success('Платеж выполнен! В ближайшее время средства будут зачислены на Ваш баланс');
          // todo write callback
          onCancel?.();
        }
      }
    } catch (e) {
      console.error(e);
      // @ts-ignore
      message.error(e);
      onCancel?.();
    }
  }, [walletClient, switchNetworkAsync, user]);

  const networkOptions = useMemo(() => {
    return uniqBy(walletsData.blockchain_wallets, (v) => v.global_id).map((wallet) => ({
      value: wallet.global_id,
      label: (
        <Space size={8} full>
          {chainIcons[wallet.global_id] && <img src={chainIcons[wallet.global_id]} alt={wallet.blockchain_name} />}
          {wallet.blockchain_name}
        </Space>
      ),
      data: { address: wallet.wallet_address },
    }));
  }, [walletsData]);

  return (
    <>
      <Modal destroyOnClose footer={null} open onCancel={onCancel}>
        <div className={styles.title}>Оплатить привязанным кошельком</div>
        <Select
          size="large"
          placeholder="Choose network"
          value={currentNetwork}
          onSelect={(value) => {
            if (walletClient?.switchChain) {
              walletClient
                ?.switchChain({id: value})
                .then(() => {
                  setCurrentNetwork(value);
                })
                .catch((e) => {
                  message.error('Cant switch chain');
                  console.warn(e);
                });
            }
          }}
          style={{width: '100%'}}
          options={networkOptions}
        />
        <Button size="large" block type="primary" disabled={!walletClient || isError} onClick={onPay}>
          Пополнить баланс
        </Button>
        <ConnectWalletModal
          chainId={currentNetwork}
          open={isConnectModalOpen}
          onCancel={() => setIsConnectModalOpen(false)}
        />
      </Modal>
    </>
  );
};
